@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}

@layer components {

  /* button  */
  .btn-blue {
    @apply py-1.5 px-4 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-red {
    @apply py-1.5 px-4 bg-red-500 text-white font-semibold rounded-md shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-green {
    @apply py-1.5 px-4 bg-green-500 text-white font-semibold rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-orange {
    @apply py-1.5 px-4 bg-orange-500 text-white font-semibold rounded-md shadow-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-opacity-75 shadow-orange-500/20 transition-all hover:shadow-lg hover:shadow-orange-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-slate {
    @apply py-1.5 px-4 bg-slate-500 text-white font-semibold rounded-md shadow-md hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-opacity-75 shadow-slate-500/20 transition-all hover:shadow-lg hover:shadow-slate-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-violet {
    @apply py-1.5 px-4 bg-violet-500 text-white font-semibold rounded-md shadow-md hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-400 focus:ring-opacity-75 shadow-violet-500/20 transition-all hover:shadow-lg hover:shadow-violet-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-indigo {
    @apply py-1.5 px-4 bg-indigo-500 text-white font-semibold rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75 shadow-indigo-500/20 transition-all hover:shadow-lg hover:shadow-indigo-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }

  /* input  */
  .form-control {
    @apply w-full border mt-1 border-gray-300 rounded-md py-1 px-3 focus:outline-none focus:border-blue-700;
  }
  /* .btn-blue {
    @apply  mr-4 rounded-md bg-blue-500 py-3 px-6 font-sans text-xs font-semibold text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  } */
  /* .btn-red {
    @apply  mr-4 rounded-md bg-red-500 py-3 px-6 font-sans text-xs font-semibold text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  } */
  /* .btn-green {
    @apply mr-4 rounded-mdn bg-green-500 py-3 px-6 font-sans text-xs font-semibold text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  }
  .btn-orange {
    @apply rounded-md bg-orange-500 py-3 px-6 font-sans text-xs font-semibold text-white shadow-md shadow-orange-500/20 transition-all hover:shadow-lg hover:shadow-orange-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
  } */

  /* .badge-green {
    @apply inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800;
  } */

  /* badge  */
  .badge-violet {
    @apply inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600;
  }
  .badge-indigo {
    @apply inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600
  }
  .badge-blue {
    @apply inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600
  }
  .badge-red {
    @apply inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600
  }
  .badge-green {
    @apply inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600
  }
  .badge-orange {
    @apply inline-flex items-center gap-1 rounded-full bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600
  }
  .badge-slate {
    @apply inline-flex items-center gap-1 rounded-full bg-slate-50 px-2 py-1 text-xs font-semibold text-slate-600
  }

  /* dot  */
  .dot-green{
    @apply h-1.5 w-1.5 rounded-full bg-green-600
  }
  .dot-red{
    @apply h-1.5 w-1.5 rounded-full bg-red-600
  }
  .dot-orange{
    @apply h-1.5 w-1.5 rounded-full bg-orange-600
  }
  .dot-blue{
    @apply h-1.5 w-1.5 rounded-full bg-blue-600
  }
  .dot-slate{
    @apply h-1.5 w-1.5 rounded-full bg-slate-600
  }
  .dot-violet{
    @apply h-1.5 w-1.5 rounded-full bg-violet-600
  }


  /* slide progress  */
  
  .progress-blue{
    @apply w-48 h-1.5 bg-blue-200 overflow-hidden rounded-full
  }
  .progress-item-blue{
    @apply bg-blue-500 w-2/3 h-1.5
  }

  .progress-red{
    @apply w-48 h-1.5 bg-red-200 overflow-hidden rounded-full
  }
  .progress-item-red{
    @apply bg-red-500 w-2/3 h-1.5
  }

  .progress-green{
    @apply w-48 h-1.5 bg-green-200 overflow-hidden rounded-full
  }
  .progress-item-green{
    @apply bg-green-500 w-2/3 h-1.5
  }

  .progress-orange{
    @apply w-48 h-1.5 bg-orange-200 overflow-hidden rounded-full
  }
  .progress-item-orange{
    @apply bg-orange-500 w-2/3 h-1.5
  }

  .progress-slate{
    @apply w-48 h-1.5 bg-slate-200 overflow-hidden rounded-full
  }
  .progress-item-slate{
    @apply bg-slate-500 w-2/3 h-1.5
  }

  .progress-violet{
    @apply w-48 h-1.5 bg-violet-200 overflow-hidden rounded-full
  }
  .progress-item-violet{
    @apply bg-violet-500 w-2/3 h-1.5
  }

  .progress-indigo{
    @apply w-48 h-1.5 bg-indigo-200 overflow-hidden rounded-full
  }
  .progress-item-indigo{
    @apply bg-indigo-500 w-2/3 h-1.5
  }



  /* table  */
  .table-wrap{
    @apply overflow-hidden border overflow-x-auto md:rounded-lg bg-white 
  }
  .table-main{
    @apply min-w-full divide-y divide-gray-200  w-full
  }
  .table-h{
    @apply bg-gray-50 py-3.5 px-4 text-sm font-medium text-left  text-gray-600
  }
  .table-b{
    @apply divide-y divide-gray-200 px-4 py-1 text-sm font-normal whitespace-nowrap text-gray-500
  }
}
