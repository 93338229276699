/* TransitionsModal.css */
::-webkit-scrollbar {
    width: 3px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    padding: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  